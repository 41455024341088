<div class="por-pay-depot-config-modal modal-content" id="apx">
  <div class="por-pay-processor-info por-pay-info-container">
    <div class="por-pay-ui-loading" *ngIf="isLoading$ | async">
        <div class="por-pay-ui-loading-header">{{ 'Loading...' | translate }}</div>
        <mat-spinner class="por-pay-ui-spinner"></mat-spinner>
    </div>
    <div *ngIf="(isLoading$ | async) === false" class="por-pay-info-content por-pay-processor-info-content" (keydown.enter)="$event.preventDefault()">
      <mat-card class="processorListCard">
        <mat-card-header>
          <mat-card-title>
            {{ getHeader() }}
          </mat-card-title>
          <mat-card-subtitle>{{ 'These are the merchants assigned to this store.' | translate }}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <div *ngIf="(processorDisplay$ | async) as processorDisplay;">
            <div *ngIf="processorDisplay.length > 0" id="processorList">
              <div class="processor-card"
                   *ngFor="let rowProcessorDisplay of processorDisplay; let i = index"
                   [ngClass]="{'invalid-credentials': rowProcessorDisplay.registrationStatus.RegistrationStatusCode === RegistrationStatusCode.Nay}"
                   [matTooltip]="getTooltipMessage(rowProcessorDisplay.registrationStatus.RegistrationStatusCode) | translate">
                <div class="processor-card-header">
                  <div class="processor-info">
                    <h3 class="processor-label">{{ rowProcessorDisplay.processor.Label }}</h3>
                    <!-- TODO Add translate  -->
                    <p class="processor-name">{{ ('Merchant: ' | translate) + getProcessorType(rowProcessorDisplay.processor) }}</p>
                  </div>
                  <div class="processor-actions" (click)="editProcessor(rowProcessorDisplay)">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path
                        d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="processorDisplay.length === 0">
              <p>{{ 'No processors available' | translate }}</p>
            </div>
          </div>
        </mat-card-content>
      </mat-card>




      <mat-card class="dropdownMethodsCard" *ngIf="dropDownMethods">
        <form [formGroup]="dropDownMethods" class="dropdownMethodsForm" (keydown.enter)="$event.preventDefault()">
          <div class="warning-section">
            <mat-card-subtitle class="warningText">
              {{ 'IT IS NOT RECOMMENDED TO CHANGE THE SETTINGS BELOW DURING BUSINESS HOURS.' | translate }}
            </mat-card-subtitle>
          </div>
          <div class="column-container">
            <div class="column" *ngIf="(inStoreCCProcessors$| async) as inStoreProcessorDisplay;">
              <mat-card-header>
                <mat-card-title>{{ 'In Store Credit Card Merchant' | translate }}</mat-card-title>
                <mat-card-subtitle>{{ 'You may only designate ONE merchant as your In Store Credit Card.' | translate }}</mat-card-subtitle>
              </mat-card-header>
              <div class="form-field-container">
                <mat-form-field id="por-pay-cc-merchant-selector" appearance="outline">
                  <mat-label>{{ 'In Store Credit Card Merchant' | translate }}</mat-label>
                  <mat-select formControlName="creditCard" placeholder="">
                    <mat-option *ngFor="let rowProcessorDisplay of inStoreProcessorDisplay; let i = index" [value]="rowProcessorDisplay.processor">
                      {{ rowProcessorDisplay.processor.Label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="column" *ngIf="(onlineCCProcessors$ | async) as onlineCCProcessorDisplay;">
              <mat-card-header>
                <mat-card-title>{{ 'Online Credit Card Merchant' | translate }}</mat-card-title>
                <mat-card-subtitle>{{ 'You may only designate ONE merchant as your Online Credit Card. This merchant will be used in your online store.' | translate }}</mat-card-subtitle>
              </mat-card-header>
              <div class="form-field-container">
                <mat-form-field id="por-pay-cf-merchant-selector" appearance="outline">
                  <mat-label>{{ 'Online Credit Card Merchant' | translate }}</mat-label>
                  <mat-select formControlName="customerFacing" placeholder="">
                    <mat-option *ngFor="let onlineCCProcessor of onlineCCProcessorDisplay; let i = index" [value]="onlineCCProcessor.processor">
                      {{ onlineCCProcessor.processor.Label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="column" *ngIf="(achProcessors$| async) as achProcessorDisplay;">
              <mat-card-header>
                <mat-card-title>{{ 'Online Bank (No Fee) Merchant' | translate }}</mat-card-title>
                <mat-card-subtitle>{{ 'You may only designate ONE merchant as your Online Bank (No Fee) Merchant. Currently, you can only accept Bank (No Fee) payments in your online store.' | translate }}</mat-card-subtitle>
              </mat-card-header>
              <div class="form-field-container">
                <mat-form-field id="por-pay-ach-merchant-selector" appearance="outline">
                  <mat-label>{{ 'Bank (No Fee) Merchant' | translate }}</mat-label>
                  <mat-select formControlName="ach" placeholder="">
                    <mat-option *ngFor="let achProcessor of achProcessorDisplay; let i = index" [value]="achProcessor.processor">
                      {{ achProcessor.processor.Label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>
      </mat-card>
    </div>
    <div class="por-pay-button-container">
      <div class="por-pay-button-save-container por-pay-right">
        <button
          apxButton
          id="por-pay-save-depot-config-edit"
          class="por-pay-process-button"
          color="primary"
          variant="primary"
          (click)="save()"
          translate="Save"></button>
        <button apxButton id="por-pay-cancel-processor-edit" class="por-pay-cancel-button" variant="secondary" (click)="cancel()">
          {{ 'Cancel' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
