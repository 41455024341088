import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, timeout } from 'rxjs';

export interface PaymentApiServiceOptions {
    body?: unknown;
    headers?: HttpHeaders;
    params?: HttpParams | { [param: string]: string | string[] };
    timeout?: number;
}

@Injectable()
export class PaymentBaseApiService {
    apiBaseUrl!: string;

    constructor(protected httpService: HttpClient) {}

    set setApiBaseUrl(url: string) {
        this.apiBaseUrl = url;
    }

  protected buildUrl(authPath: 'iam' | 'apikey' | 'cognito' | 'public', adminRoute = false): string {
    let url = new URL(this.apiBaseUrl).href;
    // Ensure the URL ends with a slash
    if (!url.endsWith('/')) {
      url += '/';
    }
    const fullPath = `${url}payment/${authPath}`;
    return adminRoute ? `${fullPath}/admin` : fullPath;
  }

    /**
     *
     * @param method
     * @param url
     * @param options
     * @returns
     */
    sendHttpRequest<T>(method: 'PUT' | 'POST' | 'GET' | 'DELETE', url: string, options?: PaymentApiServiceOptions): Observable<T> {
        let request = this.httpService.request<T>(method, url, options);

        // Apply timeout if it's set in the options
        if (options?.timeout) {
            request = request.pipe(timeout(options.timeout));
        }

        return request;
    }

    async retryUntilResponseIsNotNull<T>(fn: () => Promise<T>, retryInterval = 10): Promise<T> {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let result: T | any = null;

        while (result === null) {
            result = await fn();
            await new Promise(resolve => setTimeout(resolve, retryInterval * 1000));
        }

        return result;
    }
}
