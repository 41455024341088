<mat-card class="merchant-use-case-card">
  <mat-card-header class="merchant-use-case-header">
    <mat-card-subtitle>{{'If you would like to assign your new merchant to a use case you can do that here. Otherwise just click Next. You can always edit these options later.' | translate }}</mat-card-subtitle>
    <mat-card-subtitle>{{ 'Please Note: You may only have one merchant per use case per store.' | translate }}</mat-card-subtitle>
    <mat-card-subtitle class="warningText">{{ 'IT IS NOT RECOMMENDED TO CHANGE THE SETTINGS BELOW DURING BUSINESS HOURS.' | translate }}</mat-card-subtitle>
  </mat-card-header>
  <form *ngIf="paymentMethods" [formGroup]="paymentMethods">
    <apx-form-field class="payment-method-checkbox">
      <apx-checkbox
        formControlName="creditCard"
        id="por-pay-payment-method-{{ 'CreditCard' }}"
        class="por-pay-checkbox">
        {{ 'In Store Credit Card Merchant' | translate }}
      </apx-checkbox>
        <span class="existingProcessorLabel" *ngIf="existingCCProcessorName !== ''">
          {{'In-Store Merchant currently set to:' | translate}} {{ existingCCProcessorName | translate }}
        </span>
      <apx-checkbox
        formControlName="creditCardCustomerFacing"
        id="por-pay-payment-method-{{ 'CreditCardCustomerFacing' }}"
        class="por-pay-checkbox">
        {{ 'Online Credit Card Merchant' | translate }}
      </apx-checkbox>
        <span class="existingProcessorLabel" *ngIf="existingCFProcessorName !== ''">
        {{'Online Merchant currently set to:' | translate}} {{ existingCFProcessorName | translate }}
        </span>
      <apx-checkbox
        formControlName="aCH"
        *ngIf="selectedProcessorTypeEnum === ProcessorTypeEnum.Stripe && selectedProcessor.DefaultCurrencyCode === 'USD'"
        id="por-pay-payment-method-{{ 'ACH' }}"
        class="por-pay-checkbox">
        {{ 'Online Bank (No Fee) Merchant' | translate }}
      </apx-checkbox>
        <span class="existingProcessorLabel" *ngIf="(existingACHProcessorName !== '') && (selectedProcessorTypeEnum === ProcessorTypeEnum.Stripe) && (selectedProcessor.DefaultCurrencyCode === 'USD')">
        {{'Online Bank (No Fee) Merchant currently set to:' | translate}} {{ existingACHProcessorName | translate }}
        </span>
    </apx-form-field>
  </form>
</mat-card>
